import React, { useEffect, useState } from 'react';
import styles from './Library.module.css';
import NoPreview from './../Media/KoachView File Preview Not Available Rounded.png';
import { Link } from 'react-router-dom';

export default function HomeTop(props) {
  const { name, customMetadata, timeCreated } = props.data;
  let customName = customMetadata ? customMetadata.customName : name;
  const [thumbNailURL, setThumbNailURL] = useState(NoPreview);

  function dateConverter(date2convert) {
    const date = new Date(date2convert);
    let month = date.toLocaleString('default', { month: 'long' });
    let dateday = date.getDate();
    let year = date.getFullYear();
    return `${month} ${dateday}, ${year}`;
  }

  useEffect(() => {
    for (let i = 0; i < props.thumbnails.thumbnails.length; i++) {
      if (
        name.substring(0, name.lastIndexOf('.')) ===
        props.thumbnails.thumbnails[i].name.substring(
          0,
          props.thumbnails.thumbnails[i].name.lastIndexOf('_')
        )
      ) {
        // console.log('stop');
        setThumbNailURL(props.thumbnails.thumbnailURLs[i]);
        break;
      }
    }
    // }
  }, [name, props.thumbnails.thumbnailURLs, props]);

  function setErrorThumbnail() {
    setThumbNailURL(NoPreview);
  }

  return (
    <div
      className={'container-fluid ' + styles.library}
      style={{
        padding: '20px',
      }}
    >
      <Link to={`/library/${name}`}>
        <div className='row'>
          <div className='col-6 my-auto'>
            <img
              src={thumbNailURL}
              onError={setErrorThumbnail}
              alt=''
              style={{
                width: 'auto',
                margin: 'auto',
                display: 'block',
              }}
              className='img-fluid'
            />
          </div>
          <div className='col-6 my-auto'>
            <h1>{customName ? customName : name}</h1>
            <h5>{dateConverter(timeCreated)}</h5>
          </div>
        </div>
      </Link>
    </div>
  );
}
