import React from 'react';
import FileViewer from './FileViewerHome';

export default function PrivateHome() {
  return (
    <div
      style={{
        backgroundImage:
          'linear-gradient(176deg,rgb(248, 93, 93) 40%,rgb(245, 146, 146) calc(40% + 2px) calc(60%),#f4f4f4 calc(60% + 2px)',
        minHeight: '100vh',
      }}
    >
      <div
        style={{
          margin: '0 3%',
          padding: '1px 0',
        }}
      >
        <div style={{ margin: '30px' }}>
          <h1
            style={{
              textAlign: 'center',
              backgroundColor: 'white',
              borderRadius: '20px',
              // margin: '100px',
            }}
          >
            <span
              style={{
                padding: '10px',
                lineHeight: '1.8',
              }}
            >
              Run your Film Session like the Pros!
            </span>
          </h1>
        </div>
        <FileViewer />
      </div>
    </div>
  );
}
