import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal, Alert } from 'react-bootstrap';
import MarkedEvents from './MarkedEventsList';
import ReactPlayer from 'react-player';
import FirebaseConfiguration from './../FirebaseConfiguration';
import fileViewerStyles from './Player.module.css';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from './../Contexts/AuthContext';
import { useStorage } from './../Contexts/StorageContext';
import { useHistory } from 'react-router-dom';

export default function FileViewer(props) {
  const { currentUser } = useAuth();
  const { getUserMetadata } = useStorage();
  const [settingsModal, setSettingsModal] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [eventsListFB, setEventsListFB] = useState([]);
  const [VideoURL, setVideoURL] = useState();
  const [eventsListFBIDs, setEventsListFBIDs] = useState([]);
  const customName = React.useRef('');
  const [displayName, setDisplayName] = useState('');
  // const [sidebarWidth, setSidebarWidth] = useState('');
  const [sidebarWidth] = useState('');
  let sideBar = React.useRef(null);

  let videoPlayer = React.createRef();

  let { name } = useParams();
  // setDisplayName(name);

  async function MarkTime() {
    const db = FirebaseConfiguration.firestore();
    let time = videoPlayer.current.getCurrentTime();

    var docData3 = {
      id: uuidv4(),
      title: 'No Title',
      time_seconds: time,
      time: secondsToTime(time),
      description: '',
      date_added: new Date(),
    };
    await db
      .collection(currentUser.uid)
      .doc('videos')
      .collection(name)
      .add(docData3, { merge: true })
      .then((docRef) => {
        console.log('Document written ' + docRef.id);
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });
    // console.log(eventsListFB);
    reloadIDs();
    // console.log(eventsListFBIDs);
    setEventsListFB([...eventsListFB, docData3]);
  }

  async function saveChanges(index, eventTitle, eventDescription) {
    console.log(index);
    FirebaseConfiguration.firestore()
      .collection(currentUser.uid)
      .doc('videos')
      .collection(name)
      .doc(eventsListFBIDs[index])
      .update({
        title: eventTitle,
        description: eventDescription,
      });
    let newData = eventsListFB[index];
    newData.title = eventTitle;
    newData.description = eventDescription;
    function remove(array, element) {
      const index = array.indexOf(element);
      if (index !== -1) {
        array.splice(index, 1);
      }
    }
    let newArray = eventsListFB;
    remove(newArray, eventsListFB[index]);
    setEventsListFB([...newArray, newData]);
    console.log('save changes on Firebase');
  }

  function secondsToTime(e) {
    var h = Math.floor(e / 3600)
        .toString()
        .padStart(2, '0'),
      m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, '0'),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, '0');

    if (h === '00') {
      h = '';
    } else {
      h = `${h}:`;
    }
    if (m === '00') {
      m = '0';
    }

    return h + m + ':' + s;
  }

  function deleteEvent(index) {
    // console.log(index);
    FirebaseConfiguration.firestore()
      .collection(currentUser.uid)
      .doc('videos')
      .collection(name)
      .doc(eventsListFBIDs[index])
      .delete();
    reloadIDs();
    const updateArray = eventsListFB.filter((_, i) => i !== index);
    setEventsListFB(updateArray);
  }

  function StartAtSelectedTime(time) {
    videoPlayer.current.seekTo(time);
  }

  function CenteredSettingsModal(props) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Coming Soon</h4>
          <p>Customization Options Coming Soon</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function CenteredFileModal(props) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            File Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12'>
              <form onSubmit={changeFileName}>
                {customNameError && (
                  <Alert variant='danger'>
                    {customNameError ? customNameError : ''}
                  </Alert>
                )}
                {customNameSuccess && (
                  <Alert variant='success'>
                    {customNameSuccess ? customNameSuccess : ''}
                  </Alert>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <input
                    type='text'
                    placeholder={displayName ? displayName : name}
                    style={{ width: '55%' }}
                    ref={customName}
                  />
                  <Button onClick={() => OriginalName()}>
                    Reset to Original Name
                  </Button>
                  <Button type='submit'>Rename File</Button>
                </div>
              </form>
            </div>
            <hr style={{ marginTop: '20px' }} />
            <div className='col-12' style={{ width: '100%' }}>
              <Button
                variant='danger'
                onClick={DeleteFile}
                style={{ width: '100%' }}
              >
                Delete File and Saved Events
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  useEffect(() => {
    function loadData() {
      let db = FirebaseConfiguration.firestore();
      setEventsListFBIDs([]);
      setEventsListFB([]);
      let imgRef = db
        .collection(currentUser.uid)
        .doc('videos')
        .collection(name)
        .orderBy('time_seconds', 'asc');
      imgRef.get().then(function (snapshot) {
        if (snapshot.docs.length > 0) {
          snapshot.docs.forEach((doc) => {
            const data = doc.data();
            setEventsListFBIDs((eventsListFBIDs) => [
              ...eventsListFBIDs,
              doc.id,
            ]);
            setEventsListFB((eventsListFB) => [...eventsListFB, data]);
          });
        } else {
          console.log('No documents found');
        }
      });
    }

    function loadVideo() {
      const storageRef = FirebaseConfiguration.storage().ref();
      storageRef
        .child(currentUser.uid + '/videos/' + name)
        .getDownloadURL()
        .then(function (url) {
          setVideoURL(url);
        });
    }
    async function loadCustomName() {
      var storage = FirebaseConfiguration.storage();
      let fileMetadata = await storage
        .ref(`${currentUser.uid}/videos/${name}`)
        .getMetadata();
      if (fileMetadata.customMetadata) {
        setDisplayName(fileMetadata.customMetadata.customName);
      }
    }

    loadData();
    loadVideo();
    loadCustomName();
  }, [currentUser.uid, name]);

  function reloadIDs() {
    let db = FirebaseConfiguration.firestore();
    setEventsListFBIDs([]);
    let imgRef = db
      .collection(currentUser.uid)
      .doc('videos')
      .collection(name)
      .orderBy('time_seconds', 'asc');
    imgRef.get().then(function (snapshot) {
      if (snapshot.docs.length > 0) {
        snapshot.docs.forEach((doc) => {
          setEventsListFBIDs((eventsListFBIDs) => [...eventsListFBIDs, doc.id]);
        });
      } else {
        console.log('No marked events');
      }
    });
  }

  const history = useHistory();

  function DeleteFile() {
    console.log('DeleteFile');

    //  TODO: Implement Delete File Function as a firebase function. Deleting from JS is not recommended.
    // * https://firebase.google.com/docs/firestore/manage-data/delete-data#node.js_2

    // TODO: Delete Collection, also, eventually implement a firebase function to delete all the relevant data (thumbnail, video, database info, etc)
    // Currently, Only deletes the video file from firestore, and clears the documents from the database but leaves the collection and the video thumbnail.

    let db = FirebaseConfiguration.firestore();

    let docs = db
      .collection(currentUser.uid)
      .doc('videos')
      .collection(name)
      .orderBy('time_seconds', 'asc');
    docs.get().then(function (snapshot) {
      if (snapshot.docs.length > 0) {
        snapshot.docs.forEach((doc) => {
          doc.ref.delete();
        });
        console.log('File Data Deleted');
      } else {
        console.log('No documents found');
      }
    });

    // * This is for deleting the File, this works fine but doesn't delete the data from the database.
    FirebaseConfiguration.storage()
      .ref()
      .child(currentUser.uid + '/videos/thumbnails/' + name)
      .delete()
      .then(function () {
        console.log('File Deleted');
        history.push('/');
      });
    FirebaseConfiguration.storage()
      .ref()
      .child(currentUser.uid + '/videos/' + name)
      .delete()
      .then(function () {
        console.log('File Deleted');
        history.push('/');
      });
  }

  const [customNameError, setCustomNameError] = useState('');
  const [customNameSuccess, setCustomNameSuccess] = useState('');

  async function changeFileName(e) {
    e.preventDefault();
    // console.log(customName.current.value);
    setCustomNameError('');
    if (customName.current.value.length < 3) {
      setCustomNameError('Custom file name must be at least 3 characters.');
      return;
    }

    if (!isUserNameValid(customName.current.value)) {
      setCustomNameError(
        'Custom file name is invalid. Custom file name can only include letters, numbers, dots, and underscores.'
      );
      return;
    }
    var storage = FirebaseConfiguration.storage();
    console.log(customName.current.value);
    let newName = customName.current.value;
    await storage
      .ref(`${currentUser.uid}/videos/${name}`)
      .updateMetadata({
        customMetadata: {
          customName: customName.current.value,
        },
      })
      .then(() => {
        setDisplayName(customName.current.value);
        console.log(newName);
        setCustomNameSuccess('File successfully renamed to ' + newName + '.');
      });
  }

  async function OriginalName() {
    var storage = FirebaseConfiguration.storage();
    // console.log(customName.current.value);
    await storage
      .ref(`${currentUser.uid}/videos/${name}`)
      .updateMetadata({
        customMetadata: {
          customName: '',
        },
      })
      .then(() => {
        setDisplayName('');
        setCustomNameSuccess('Reset file name to original name: ' + name + '.');
      });
  }

  function isUserNameValid(username) {
    /* 
    Usernames can only have: 
    - Lowercase Letters (a-z) 
    - Uppercase Letters (A-Z) 
    - Numbers (0-9)
    - Dots (.)
    - Underscores (_)
  */
    // eslint-disable-next-line
    const res = /^[a-zA-z0-9_\.]+$/.exec(username);
    const valid = !!res;
    return valid;
  }

  function closeSidebar() {
    setHideSidebar(!hideSidebar);
  }

  function closeFileModal() {
    getUserMetadata();
    setFileModal(false);
    setCustomNameError('');
    setCustomNameSuccess('');
  }

  const [hideSidebar, setHideSidebar] = useState(true);

  return (
    <div className='container-fluid' style={{ minHeight: '95vh' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '10px',
        }}
      >
        <h3>{displayName ? displayName : name}</h3>
        <Button
          className='btn btn-secondary'
          onClick={() => setFileModal(true)}
        >
          <h1>
            <i
              className='fa fa-gear'
              style={{ fontSize: '24px', textAlign: 'center' }}
            ></i>
          </h1>
        </Button>
        <CenteredFileModal show={fileModal} onHide={closeFileModal} />
      </div>
      <div className='row'>
        <div className='col'>
          <div
            className={fileViewerStyles['wrapper']}
            style={{ marginBottom: '30px' }}
          >
            <ReactPlayer
              ref={videoPlayer}
              className={fileViewerStyles['player']}
              url={VideoURL}
              width='100%'
              height='100%'
              controls
            />
          </div>
          <form>
            <div className={'row ' + fileViewerStyles['space-top']}>
              <div className='col-10'>
                <Button className='col-12' onClick={MarkTime}>
                  <h1>Mark Event</h1>
                </Button>
              </div>
              <div className='col-2'>
                <Button
                  className='col-12 btn btn-secondary'
                  onClick={() => setSettingsModal(true)}
                >
                  <h1>
                    <i
                      className='fa fa-gear'
                      style={{ fontSize: '24px', textAlign: 'center' }}
                    ></i>
                  </h1>
                </Button>
                <CenteredSettingsModal
                  show={settingsModal}
                  onHide={() => setSettingsModal(false)}
                />
              </div>
            </div>
          </form>
        </div>

        <div
          className='col-auto d-none d-lg-xl d-lg-block '
          style={{ padding: '0px' }}
        >
          <button
            onClick={closeSidebar}
            className='active'
            style={{
              height: '100%',
              width: '50px',
              background: '#F8F8F8',
              border: '1px solid #484848',
              fontSize: '40px',
            }}
          >
            &rsaquo;
          </button>
        </div>
        <div
          className={
            hideSidebar
              ? 'col-3 d-none d-lg-xl d-lg-block ' +
                fileViewerStyles['sidebar-visible']
              : fileViewerStyles['sidebar-hidden']
          }
        >
          <div
            ref={sideBar}
            style={{
              maxWidth: `${sidebarWidth}px`,
              overflow: 'hidden',
              transition: 'max-width .25s',
              // whiteSpace: 'nowrap',
            }}
          >
            <div
              ref={sideBar}
              style={{
                // maxWidth: `${sidebarWidth}px`,
                right: `${sidebarWidth}px`,
                // overflow: 'hidden',
                transition: 'max-width 10s',
                // border: '1px solid black',
              }}
            >
              <div style={{ padding: '10px 0px' }}>
                <h1
                  style={{
                    textAlign: 'center',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Marked Events
                </h1>
                <div className={fileViewerStyles['side_list']}>
                  <MarkedEvents
                    hideSidebar={hideSidebar}
                    events={eventsListFB}
                    deleteEvent={deleteEvent}
                    StartAtSelectedTime={StartAtSelectedTime}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: '30px 0px',
        }}
      >
        <hr
          style={{
            borderBottom: 'dashed 3px',
            color: 'red',
          }}
        />
      </div>
      <div className='row'>
        <div className='col-12' key={eventsListFB.id}>
          {eventsListFB.length === 0 && (
            <div style={{ margin: '30px 0px 50px', textAlign: 'center' }}>
              <h1>No Events Marked Yet</h1>
            </div>
          )}
          {/* Large View With Edit Event */}
          <div>
            <MarkedEvents
              events={eventsListFB}
              user={props.user}
              large={true}
              deleteEvent={deleteEvent}
              saveChanges={saveChanges} //!TODO: Implement Firebase Save Changes
              StartAtSelectedTime={StartAtSelectedTime}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
