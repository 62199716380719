import React from 'react';
import PropTypes from 'prop-types';
import styles from './MarkedEvent.module.css';
import fileViewerStyles from './Player.module.css';

export default function MarkedEvent(props) {
  return (
    <div>
      <div style={{ display: 'flex', padding: '5px' }}>
        <button
          className={styles['event-button']}
          onClick={() => props.StartAtSelectedTime(props.event.time_seconds)}
        >
          <h3
            className={
              props.hideSidebar ? '' : fileViewerStyles['hide-overflow']
            }
          >
            {props.event.time} - {props.event.title}
          </h3>
          <p
            className={
              props.hideSidebar ? '' : fileViewerStyles['hide-overflow']
            }
            style={{ margin: '0px' }}
          >
            {props.event.description}
          </p>
        </button>
        <button
          onClick={() => props.deleteEvent(props.index)}
          className={styles['event-delete-button']}
        >
          x
        </button>
      </div>
      {/* <table
        style={{
          width: '100%',
          marginBottom: '10px',
          border: '1px solid black',
        }}
      >
        <tbody>
          <tr>
            <td>
              <EventButton
                onClick={() =>
                  props.StartAtSelectedTime(props.event.time_seconds)
                }
              >
                <h3>
                  {props.event.time} - {props.event.title}
                </h3>
              </EventButton>
            </td>
            <td style={{ width: '10%', position: 'relative' }}>
              <RemoveButton onClick={() => props.deleteEvent(props.index)}>
                x
              </RemoveButton>
            </td>
          </tr>
          <tr>
            <td>{props.event.description}</td>
          </tr>
        </tbody>
      </table> */}
    </div>
  );
}

MarkedEvent.propTypes = {
  event: PropTypes.object.isRequired,
};
