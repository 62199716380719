import React, { useRef, useEffect } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from './../Contexts/AuthContext';
import Navbar from '../Navigation/Navbar/Navbar2';
import Footer from '../Navigation/Footer';
import styles from './Login.module.css';

export default function ForgotPassword() {
  const { currentUser } = useAuth();

  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [firebaseError, setFirebaseError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setError('');
      setMessage('');
      setFirebaseError('');
      setLoading(true);
      await resetPassword(emailRef.current.value).catch((err) => {
        setFirebaseError(err.message);
      });
      setMessage('Check your email for further instructions.');
    } catch {
      setError('Failed to reset password.');
      console.log('setError');
    }
    setLoading(false);
  }

  useEffect(() => {
    if (currentUser) {
      history.push('/');
    }
  });

  return (
    <div>
      <Navbar />
      <div
        className='container-fluid d-flex align-items-center justify-content-center'
        style={{ minHeight: '100vh', background: '#e9e9e9' }}
      >
        <div className='w-100' style={{ maxWidth: '500px' }}>
          <div>
            <Card
              style={{
                borderRadius: '30px',
                boxShadow: '0 50px 70px -20px rgba(0, 0, 0, 0.8)',
                padding: '40px',
              }}
            >
              <Card.Body>
                <h2 className='text-center mb-4'>Password Reset</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                {firebaseError && (
                  <Alert variant='danger'>{firebaseError}</Alert>
                )}
                {message && <Alert variant='success'>{message}</Alert>}
                <Form onSubmit={handleSubmit}>
                  <Form.Group id='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      className={styles['auth-input']}
                      type='email'
                      ref={emailRef}
                      required
                    />
                  </Form.Group>

                  <Button
                    disabled={loading}
                    variant='primary'
                    type='submit'
                    className={
                      styles['auth-button'] + ' mt-4 w-100 shadow-none'
                    }
                  >
                    Reset Password
                  </Button>
                </Form>
                <div className='w-100 text-center mt-3'>
                  <Link to='/login' className={styles['switch-button']}>
                    Login
                  </Link>
                </div>
              </Card.Body>
              <div style={{ textAlign: 'right' }} className='w-100 mt-2'>
                Need an Account?{' '}
                <Link to='sign-up' className={styles['switch-button']}>
                  Sign Up
                </Link>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
