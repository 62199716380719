import React, { useEffect, useState } from 'react';
import styles from './Library.module.css';
import NoPreview from './../Media/KoachView File Preview Not Available Rounded.png';
import { Link } from 'react-router-dom';

export default function LibraryIndexes(props) {
  const { name, customMetadata, timeCreated } = props.data;
  let customName = customMetadata ? customMetadata.customName : name;
  const [thumbNailURL, setThumbNailURL] = useState(NoPreview);

  function dateConverter(date2convert) {
    const date = new Date(date2convert);
    let month = date.toLocaleString('default', { month: 'long' });
    let dateday = date.getDate();
    let year = date.getFullYear();
    return `${month} ${dateday}, ${year}`;
  }

  useEffect(() => {
    for (let i = 0; i < props.thumbnails.thumbnails.length; i++) {
      if (
        name.substring(0, name.lastIndexOf('.')) ===
        props.thumbnails.thumbnails[i].name.substring(
          0,
          props.thumbnails.thumbnails[i].name.lastIndexOf('_')
        )
      ) {
        setThumbNailURL(props.thumbnails.thumbnailURLs[i]);
        break;
      }
    }
  }, [name, props.thumbnails.thumbnailURLs, props]);

  return (
    <div>
      <div className={'container-fluid ' + styles.library}>
        <Link to={`/library/${name}`}>
          <div className='row' style={{ margin: ' 20px' }}>
            {/* <div style={{  height: '305px' }}> */}
            <div className='col-4 my-auto'>
              <img
                src={thumbNailURL}
                alt=''
                style={{
                  margin: 'auto',
                  display: 'block',
                  width: 'auto',
                  maxHeight: '300px',
                }}
                className='img-fluid'
              />
            </div>
            <div className='col-8 my-auto'>
              <h1>{customName ? customName : name}</h1>
              <h5>{dateConverter(timeCreated)}</h5>
            </div>
          </div>
        </Link>
      </div>
      {props.fileLength - 1 !== props.index && <hr style={{ margin: '0px' }} />}
    </div>
  );
}
