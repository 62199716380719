import React, { useState, useEffect } from 'react';
import fire from './../../FirebaseConfiguration';
import styled from 'styled-components';
import { useAuth } from '../../Contexts/AuthContext';

const SquareImg = styled.img`
  object-fit: cover;
  width: 230px;
  height: 230px;
  margin: 10px;
  border: 10px solid white;
`;

export default function Settings() {
  const { currentUser } = useAuth();

  const [uploadProgress, setUploadProgress] = useState([]);
  const [fileMetaData, setFileMetaData] = useState([]);

  let upload_location = `${currentUser.uid}/team_assets`;

  useEffect(() => {
    async function getUserData() {
      let urls = await getUserURLs();
      // console.log(urls);
      let metadata = await getUserMetadata();
      metadata.forEach((file, index) => {
        file.url = urls[index];
      });
      console.log(metadata);
      // console.log(metadata);
      return metadata;
    }

    async function getUserMetadata() {
      var storage = fire.storage();
      let allFiles = await storage.ref(upload_location).listAll();
      // console.log(allFiles);
      const itemsMeta = allFiles.items.map((ref) => ref.getMetadata());
      const meta = await Promise.all(itemsMeta);
      // console.log(meta);
      return meta;
    }

    async function getUserURLs() {
      var storage = fire.storage();
      let allFiles = await storage.ref(upload_location).listAll();
      // console.log(allFiles);
      const itemsURLs = allFiles.items.map((ref) => ref.getDownloadURL());
      const urls = await Promise.all(itemsURLs);
      // console.log(urls);
      return urls;
    }

    async function getData() {
      await getUserData().then((data) => {
        setFileMetaData(data);
      });
    }

    getData();
  }, [upload_location]);

  function handleFileSubmit(files) {
    for (let i = 0; i < files.target.files.length; i++) {
      let file = files.target.files[i];
      console.log(file);
      var uploadTask = fire
        .storage()
        .ref()
        .child(`${upload_location}/${file.name}`)
        .put(file);
      let progressA = 0;
      console.log(file.name);
      uploadProgress.push({ [file.name]: progressA });
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          progressA = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log(
            'Upload for ' + file.name + ' is ' + progressA + '% done'
          );
          uploadProgress[file.name] = progressA;
          console.log(uploadProgress);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  return (
    <div style={{ minHeight: '97vh' }} className='container-fluid'>
      {/* <h1 style={{ textAlign: 'center' }}>Settings Coming Soon.</h1> */}
      <input
        type='file'
        accept='image/*'
        multiple
        onChange={(files) => handleFileSubmit(files)}
      />
      <div className='row'>
        <div
          style={{
            border: '1px solid black',
            background: 'grey',
            minHeight: '230px',
          }}
        >
          {fileMetaData.length > 0 && (
            <>
              {fileMetaData
                .sort((a, b) => (a.timeCreated > b.timeCreated ? 1 : -1))
                .map((metadata) => {
                  console.log(metadata.timeCreated);
                  return (
                    <SquareImg src={metadata.url} key={metadata.url} alt='' />
                  );
                })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
