import React, { useRef, useState } from 'react';
import fire from './../FirebaseConfiguration';
import { Form, Button, Card, Alert, Modal } from 'react-bootstrap';
import { useAuth } from './../Contexts/AuthContext';
import styles from './Login.module.css';
import firebase from 'firebase/app';
import ManageSettings from './ManageAccount/ManageAccount';
import AccountStyles from './AccountStyles.module.css';

export default function Account() {
  const [ShowChangePassword, setShowChangePassword] = useState(null);
  const { currentUser } = useAuth();

  return (
    <div style={{ minHeight: '90vh', textAlign: 'center' }}>
      {/* background: '#e9e9e9' */}
      <h1 style={{ marginBottom: '20px' }}>Manage Account</h1>

      <p>
        Email (cannot change):{' '}
        <input value={currentUser.email} disabled></input>
      </p>

      {ShowChangePassword && (
        <ChangePassword setShowChangePassword={setShowChangePassword} />
      )}
      {!ShowChangePassword && (
        <>
          <button
            onClick={() => setShowChangePassword(true)}
            className={AccountStyles.button}
          >
            Change Password
          </button>
          <DeleteAccount />
        </>
      )}
      {/* <ManageSettings /> */}
    </div>
  );
}

function DeleteAccount() {
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const handleShowDeleteAccount = () => setShowDeleteAccount(true);
  const { currentUser } = useAuth();

  const emailRef = useRef();
  const passwordRef = useRef();

  const handleCloseDeleteAccount = () => setShowDeleteAccount(false);

  const [error, setError] = useState(null);

  const [firebaseError, setFirebaseError] = useState('');
  const [showError, setShowError] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const handleCloseDeleteError = () => setShowError(false);
  const handleShowDeleteError = () => setShowError(true);

  const [showReAuthRequired, setShowReAuthRequired] = useState(false);
  const handleCloseReAuthRequired = () => {
    setShowReAuthRequired(false);
    setFirebaseError('');
    console.log('close');
  };
  const handleShowReAuthRequired = () => {
    setShowReAuthRequired(true);
  };

  function deleteAccount() {
    const credential = firebase.auth.EmailAuthProvider.credential(
      emailRef.current.value,
      passwordRef.current.value
    );

    // Todo: add firebase function to delete the users database data.

    currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        console.log('then');
        currentUser
          .delete()
          .then(() => {
            console.log('User deleted');
            console.log('deleted');
            console.log(currentUser.uid);
          })
          .catch((error) => {
            handleCloseDeleteAccount();
            handleShowDeleteError();
            setDeleteError(error.message);
            console.log('not deleted');
          });
      })
      .catch((err) => {
        console.log(err);
        switch (err.code) {
          case 'auth/wrong-password':
            setFirebaseError(
              'The password you provided is incorrect. Please try again.'
            );
            break;
          default:
            setFirebaseError(err.message);
        }
      });
  }
  return (
    <div>
      <button
        onClick={handleShowDeleteAccount}
        className={AccountStyles.button}
      >
        Delete Account
      </button>

      {/* Delete Your Account */}
      <Modal show={showDeleteAccount} onHide={handleCloseDeleteAccount}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete your account? This cannot be undone.
          </Modal.Title>
        </Modal.Header>
        {error && <div className='alert alert-danger'>{error}</div>}
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseDeleteAccount}>
            Cancel
          </Button>
          {/* <Button variant='danger' onClick={deleteAccount}> */}
          <Button variant='danger' onClick={handleShowReAuthRequired}>
            Delete Account
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Error When Deleting Account */}
      <Modal show={showError} onHide={handleCloseDeleteError}>
        <Modal.Header closeButton>
          <Modal.Title>{deleteError}</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant='danger' onClick={handleCloseDeleteError}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  */}
      <Modal show={showReAuthRequired} onHide={handleCloseReAuthRequired}>
        <Modal.Header closeButton>
          <Modal.Title>
            Please enter your username and password to confirm you would like to
            delete your account.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {firebaseError && <Alert variant='danger'>{firebaseError}</Alert>}
            <Form.Group id='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                className={styles['auth-input']}
                type='email'
                placeholder='email@email.com'
                ref={emailRef}
                required
              />
            </Form.Group>
            <Form.Group id='password' className='mt-4'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                className={styles['auth-input']}
                placeholder='Password'
                type='password'
                ref={passwordRef}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseReAuthRequired}>
            Cancel
          </Button>
          <Button variant='danger' onClick={deleteAccount}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function ChangePassword(props) {
  const { currentUser } = useAuth();

  const newPassword = useRef(null);
  const newPasswordConfirm = useRef(null);
  const currentPassword = useRef(null);

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  function changePassword(e) {
    e.preventDefault();
    setError();
    setSuccess();
    console.log(newPassword.current.value);
    if (newPassword.current.value === newPasswordConfirm.current.value) {
      fire
        .auth()
        .signInWithEmailAndPassword(
          currentUser.email,
          currentPassword.current.value
        )
        .then(function (user) {
          fire
            .auth()
            .currentUser.updatePassword(newPassword.current.value)
            .then(function () {
              setSuccess('Password changed successfully.');
            })
            .catch(function (err) {
              setError(err.message + '.');
            });
        })
        .catch(function (err) {
          setError('Current Password is incorrect.');
          console.log(err.message);
        });
    } else {
      setError('New Passwords do not match');
    }
  }

  return (
    <div className='container-fluid'>
      <div className='d-flex align-items-center justify-content-center'>
        {/* <button onClick={() => props.setShowChangePassword(null)}>Close</button> */}
        <div className='w-100' style={{ maxWidth: '500px' }}>
          <div>
            <Card
              style={{
                borderRadius: '30px',
                boxShadow: '0 50px 70px -20px rgba(0, 0, 0, 0.8)',
                padding: '40px',
              }}
            >
              <Card.Body>
                <button
                  onClick={() => props.setShowChangePassword(null)}
                  style={{ float: 'right' }}
                >
                  X
                </button>

                <h2 className='text-center mb-4'>Change Password</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                {success && <Alert variant='success'>{success}</Alert>}
                <Form onSubmit={changePassword}>
                  <Form.Group id='email'>
                    <Form.Label>Current Password:</Form.Label>
                    <Form.Control
                      className={styles['auth-input']}
                      type='password'
                      placeholder='Current Password'
                      ref={currentPassword}
                      required
                    />
                  </Form.Group>
                  <Form.Group id='password' className='mt-4'>
                    <Form.Label>New Password:</Form.Label>
                    <Form.Control
                      className={styles['auth-input']}
                      type='password'
                      placeholder='New Password'
                      ref={newPassword}
                      required
                    />
                  </Form.Group>
                  <Form.Group id='password' className='mt-4'>
                    <Form.Label>New Password Confirmation:</Form.Label>
                    <Form.Control
                      className={styles['auth-input']}
                      type='password'
                      placeholder='New Confirmation Password'
                      ref={newPasswordConfirm}
                      required
                    />
                  </Form.Group>
                  <Button
                    className={styles['auth-button'] + ' mt-4 w-100'}
                    type='submit'
                  >
                    Change Password
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
