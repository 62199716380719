import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { AuthProvider } from './Contexts/AuthContext';

import PrivateRoute from './PrivateRoute';

import SignUp from './Account/SignUp';
import Login from './Account/Login';
import ForgotPassword from './Account/ForgotPassword';

import Home from './Home/Home';
import Dashboard from './Home/Dashboard';
import FileViewer from './FileViewer/FileViewer';
import Account from './Account/Account';

import PublicHome from './No Account/Home';

export default function Routes() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <PrivateRoute
            exact
            path='/'
            component={Home}
            publicComponent={PublicHome}
          />
          <PrivateRoute exact path='/library' component={Dashboard} />
          <PrivateRoute path='/library/:name' component={FileViewer} />
          <PrivateRoute path='/account' component={Account} />

          <Route path='/sign-up' component={SignUp} />
          <Route path='/login' component={Login} />
          <Route path='/reset-password' component={ForgotPassword} />

          <Redirect to='/' />
        </Switch>
      </AuthProvider>
    </Router>
  );
}
