import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EditView from './EditView';

const EventButton = styled.button`
  -webkit-appearance: none;
  text-decoration: none;
  /* background-color: red; */
  background-color: white;
  border: none;
  padding: 20px 10px;
  text-align: left;
  margin-right: 0px;
  /* text-decoration: none; */
  display: block;
  width: 100%;
  border: none;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
`;

const EditButton = styled.button`
  -webkit-appearance: none;
  text-decoration: none;
  background-color: red;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  width: 100%;
`;

const RemoveButton = styled.button`
  -webkit-appearance: none;
  text-decoration: none;
  background-color: black;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  width: 100%;
`;

export default function MarkedEvent(props) {
  const [editMode, setEditMode] = useState(true);

  function switchOutOfEditMode() {
    setEditMode(true);
  }

  const { description, time, title, time_seconds } = props.event;
  return (
    <div>
      {editMode ? (
        <div>
          <table
            style={{
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <tbody>
              <tr
                style={{
                  // backgroundColor: 'green',
                  width: '100%',
                }}
              >
                <td>
                  <h4>
                    <EventButton
                      onClick={() => props.StartAtSelectedTime(time_seconds)}
                    >
                      <h3>
                        {time} - {title}
                      </h3>
                    </EventButton>
                  </h4>
                </td>
                <td style={{ width: '10%', position: 'relative' }}>
                  <EditButton onClick={() => setEditMode(false)}>
                    Edit
                  </EditButton>
                </td>
                <td style={{ width: '10%', position: 'relative' }}>
                  <RemoveButton onClick={() => props.deleteEvent(props.index)}>
                    Delete
                  </RemoveButton>
                </td>
              </tr>
              <tr>
                <td>
                  <p style={{ marginLeft: '40px', fontWeight: 'normal' }}>
                    {description}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
        </div>
      ) : (
        <EditView
          switchOutOfEditMode={switchOutOfEditMode}
          saveChanges={props.saveChanges}
          eventData={props.event}
          index={props.index}
        />
      )}
    </div>
  );
}

MarkedEvent.propTypes = {
  event: PropTypes.object.isRequired,
};
