import React, { useContext, useState, useReducer } from 'react';
import fire from './../FirebaseConfiguration';
import { useAuth } from './AuthContext';

const StorageContext = React.createContext();

export function useStorage() {
  return useContext(StorageContext);
}

export function StorageProvider({ children }) {
  const { currentUser } = useAuth();
  const [metadata, setMetadata] = useState([]);

  const ACTIONS = {
    SET_THUMBNAILS: 'SET_THUMBNAILS',
    SET_METADATA: 'SET_METADATA',
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_THUMBNAILS':
        state.thumbnails[action.payload.category] = action.payload.data;
        return { ...state };
      case 'SET_METADATA':
        return { ...state, metadata: action.payload };

      default:
        return state;
    }
  };

  const [stateContext, dispatch] = useReducer(reducer, {
    metadata: [],
    thumbnails: {},
  });

  async function getUserMetadata() {
    console.log('Contacting DB for Metadata');
    var storage = fire.storage();
    let allFiles = await storage.ref(`${currentUser.uid}/videos`).listAll();
    const itemsMeta = allFiles.items.map((ref) => ref.getMetadata());
    const metas = await Promise.all(itemsMeta);
    setMetadata(metas);
    return;
  }

  async function getUserThumbnails() {
    console.log('Contacting DB for Thumbnails');
    var storage = fire.storage();
    let allFiles = await storage
      .ref(`${currentUser.uid}/videos/thumbnails`)
      .listAll();
    // console.log(allFiles);
    const itemsThumbnails = allFiles.items.map((ref) => ref.getMetadata());
    const thumbnails = await Promise.all(itemsThumbnails);
    // console.log(meta);
    let thumbnailURLs = await Promise.all(
      allFiles.items.map((ref) => ref.getDownloadURL())
    );

    dispatch({
      type: ACTIONS.SET_THUMBNAILS,
      payload: {
        category: 'thumbnailURLs',
        data: thumbnailURLs,
      },
    });
    dispatch({
      type: ACTIONS.SET_THUMBNAILS,
      payload: {
        category: 'thumbnails',
        data: thumbnails,
      },
    });
    return;
  }

  const value = {
    getUserMetadata,
    getUserThumbnails,
    setMetadata,
    metadata,
    stateContext,
  };
  return (
    <StorageContext.Provider value={value}>{children}</StorageContext.Provider>
  );
}
