import React from 'react';
import MarkedEvent from './MarkedEvent';
import MarkedEventLarge from './MarkedEventLarge2';
import PropTypes from 'prop-types';

const MarkedEventsList = (props) => {
  // return <div>{console.log(props.events)}</div>;
  if (props.large === true) {
    return props.events
      .sort((a, b) => (a.time_seconds > b.time_seconds ? 1 : -1))
      .map((event, i) => (
        <MarkedEventLarge
          key={event.id}
          index={i}
          event={event}
          saveChanges={props.saveChanges}
          deleteEvent={props.deleteEvent}
          switchOutOfEditMode={props.switchOutOfEditMode}
          editMode={props.editMode}
          StartAtSelectedTime={props.StartAtSelectedTime}
        />
      ));
  } else {
    return props.events
      .sort((a, b) => (a.time_seconds > b.time_seconds ? 1 : -1))
      .map((event, i) => (
        <MarkedEvent
          key={event.id}
          index={i}
          hideSidebar={props.hideSidebar}
          event={event}
          deleteEvent={props.deleteEvent}
          saveChanges={props.saveChanges}
          StartAtSelectedTime={props.StartAtSelectedTime}
        />
      ));
  }
};

MarkedEventsList.propTypes = {
  events: PropTypes.array.isRequired,
};

export default MarkedEventsList;
