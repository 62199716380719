import React, { useRef, useEffect } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from '../Navigation/Navbar/Navbar2';
import Footer from '../Navigation/Footer';
import { useAuth } from './../Contexts/AuthContext';
import styles from './Login.module.css';

export default function Login() {
  const { currentUser } = useAuth();

  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = React.useState('');
  const [firebaseError, setFirebaseError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  async function handleSubmit(event) {
    event.preventDefault();
    setError('');
    setFirebaseError('');
    setLoading(true);
    await login(emailRef.current.value, passwordRef.current.value).catch(
      (err) => {
        console.log(err);
        switch (err.code) {
          default:
            setFirebaseError(err.message);
        }
      }
    );

    setLoading(false);
  }

  useEffect(() => {
    if (currentUser) {
      history.push('/');
    }
  });

  return (
    <div>
      <NavBar />
      <div
        className='container-fluid d-flex align-items-center justify-content-center'
        style={{ minHeight: '100vh', background: '#e9e9e9' }}
      >
        <div className='w-100' style={{ maxWidth: '500px' }}>
          <div>
            <Card
              style={{
                borderRadius: '30px',
                boxShadow: '0 50px 70px -20px rgba(0, 0, 0, 0.8)',
                padding: '40px',
              }}
            >
              <Card.Body>
                <h2 className='text-center mb-4'>Log In To KoachView</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                {firebaseError && (
                  <Alert variant='danger'>{firebaseError}</Alert>
                )}
                <Form onSubmit={handleSubmit}>
                  <Form.Group id='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      className={styles['auth-input']}
                      type='email'
                      placeholder='email@email.com'
                      ref={emailRef}
                      required
                    />
                  </Form.Group>
                  <Form.Group id='password' className='mt-4'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      className={styles['auth-input']}
                      placeholder='Password'
                      type='password'
                      ref={passwordRef}
                      required
                    />
                  </Form.Group>
                  <Button
                    className={
                      // loading
                      // ? styles['auth-button-submit']
                      // : '' +
                      styles['auth-button'] + ' mt-4 w-100 shadow-none'
                    }
                    // className='mt-4 w-100'
                    disabled={loading}
                    type='submit'
                  >
                    Login
                  </Button>
                </Form>
                <div className='w-100 text-center mt-3'>
                  <Link
                    to='/reset-password'
                    className={styles['switch-button']}
                  >
                    Forgot Password?
                  </Link>
                </div>
                <div style={{ textAlign: 'right' }} className='w-100 mt-2'>
                  Don't have an Account?{' '}
                  <Link to='sign-up' className={styles['switch-button']}>
                    Sign Up
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
