import React, { useEffect, useReducer } from 'react';
import Fuse from 'fuse.js';
import FirebaseConfiguration from './../FirebaseConfiguration';
import { useAuth } from './../Contexts/AuthContext';
import { useStorage } from './../Contexts/StorageContext';
import LibraryIndexes from './LibraryIndexes';
import DropElement from './DropElement';
import Loading from './../Loading';

export default function Home() {
  const { currentUser } = useAuth();
  const { metadata, stateContext, getUserMetadata, getUserThumbnails } =
    useStorage();

  const ACTIONS = {
    SET_LOADING: 'SET_LOADING',
    SET_FILES: 'SET_FILES',
    SET_ERROR_FILES: 'SET_ERROR_FILES',
    SET_NEW_FILES: 'SET_NEW_FILES',
    SET_THUMBNAILS: 'SET_THUMBNAILS',
    SET_SEARCH: 'SET_SEARCH',
    SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',
    SET_METADATA: 'SET_METADATA',
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_LOADING':
        return { ...state, loading: true };
      case 'SET_SEARCH':
        return { ...state, search: action.payload };
      case 'SET_FILES':
        return { ...state, files: action.payload };
      case 'SET_ERROR_FILES':
        return { ...state, errorFiles: action.payload };
      case 'SET_THUMBNAILS':
        return { ...state, thumbnails: action.payload };
      case 'SET_METADATA':
        return { ...state, metadata: action.payload };
      case 'SET_UPLOAD_PROGRESS':
        state.uploadProgress[action.payload.file_name] =
          action.payload.progress;
        return { ...state };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    search: '',
    files: [],
    errorFiles: [],
    newFiles: [],
    uploadProgress: {},
  });

  useEffect(() => {
    async function getData() {
      if (metadata.length === 0) {
        getUserMetadata();
      }

      if (Object.keys(stateContext.thumbnails).length === 0) {
        await getUserThumbnails();
      }

      if (metadata.length > 0) {
        dispatch({ type: ACTIONS.SET_LOADING });
      }
    }

    getData();
  }, [
    dispatch,
    metadata.length,
    stateContext.thumbnails,
    getUserMetadata,
    getUserThumbnails,
    ACTIONS.SET_LOADING,
  ]);

  async function handleAcceptedFiles(files) {
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (isNameValid(file.name)) {
        var uploadTask = FirebaseConfiguration.storage()
          .ref()
          .child(`${currentUser.uid}/videos/${file.name}`)
          .put(file);
        let progress = 0;
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(
              'Upload for ' + file.name + ' is ' + progress + '% done'
            );
            console.log(file.name);
            dispatch({
              type: ACTIONS.SET_NEW_FILES,
              payload: file.name,
            });
            dispatch({
              type: ACTIONS.SET_UPLOAD_PROGRESS,
              payload: { file_name: file.name, progress: progress },
            });
            if (progress === 100) {
              getUserMetadata();
              // getUserData().then((data) => {
              //   dispatch({
              //     type: ACTIONS.SET_METADATA,
              //     payload: data.metadata,
              //   });
              // });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        console.log(file.name);

        dispatch({
          type: ACTIONS.SET_UPLOAD_PROGRESS,
          payload: {
            file_name: file.name,
            progress:
              '⚠️ File rejected. File name can only include letters, numbers, dots (.), underscores, and spaces.',
          },
        });
        dispatch({
          type: ACTIONS.SET_NEW_FILES,
          payload: file.name,
        });
      }
    }
  }

  function isNameValid(fileName) {
    /* 
    Usernames can only have: 
    - Lowercase Letters (a-z) 
    - Numbers (0-9)
    - Dots (.)
    - Underscores (_)
  */
    // eslint-disable-next-line
    const res = /^[A-Za-z0-9_\. ]+$/.exec(fileName);
    const valid = !!res;
    return valid;
  }

  function handleRejectedFiles(rejectedFiles) {
    for (let index = 0; index < rejectedFiles.length; index++) {
      const file_name = rejectedFiles[index].file.name;

      dispatch({
        type: ACTIONS.SET_UPLOAD_PROGRESS,
        payload: {
          file_name: file_name,
          progress: '⚠️ File rejected. Only video files can be uploaded.',
        },
      });
      dispatch({
        type: ACTIONS.SET_NEW_FILES,
        payload: file_name,
      });
    }
  }

  const fuse = new Fuse(metadata, {
    keys: ['name', 'customName'],
    // includeScore: true,
    // keys: ['name', 'customName', 'timeCreated'],
    // threshold: 0.2,
    // distance: 100,
    // includeMatches: true,
    // includeScore: true,
    // minMatchCharLength: 1,
    // shouldSort: true,
    // findAllMatches: true,
    // location: 0,
    // maxPatternLength: 32,
  });

  const searcher = fuse.search(state.search);

  const searchResults = state.search
    ? searcher.map((result) => result.item)
    : metadata.sort((a, b) => (a.timeCreated < b.timeCreated ? 1 : -1));

  return (
    <div style={{ minHeight: '97vh' }}>
      {state.loading ? (
        <div>
          <DropElement
            handleAcceptedFiles={handleAcceptedFiles}
            handleRejectedFiles={handleRejectedFiles}
            newUploadProgress={state.uploadProgress}
            newFilesList={state.newFiles}
          />
          <div style={{ margin: '25px' }}>
            <input
              type='text'
              placeholder='Search'
              style={{ width: '100%' }}
              value={state.search}
              onChange={(e) => {
                dispatch({ type: ACTIONS.SET_SEARCH, payload: e.target.value });
              }}
            />
          </div>
          <>
            {searchResults.length > 0 ? (
              <div>
                {searchResults.map((file, i) => (
                  <LibraryIndexes
                    thumbnails={stateContext.thumbnails}
                    key={file.generation}
                    data={file}
                  />
                ))}
              </div>
            ) : (
              <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                {metadata.length > 0 ? (
                  <h1>No Results</h1>
                ) : (
                  <h1>Upload your first file to begin.</h1>
                )}
              </div>
            )}
          </>
        </div>
      ) : (
        <Loading color={'red'} />
      )}
    </div>
  );
}
