import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getColorBackground = (props) => {
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#999999';
  }
  return '#fafafa';
};

const DropArea = styled.div`
  flex: 1;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  padding: 30px;
  border-width: 2px;
  border: 4px solid black;
  border-radius: 30px;
  background-color: ${(props) => getColorBackground(props)};
  // color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

function DropElement(props) {
  // eslint-disable-next-line
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      props.handleRejectedFiles(rejectedFiles);
    }

    if (acceptedFiles.length) {
      props.handleAcceptedFiles(acceptedFiles);
    }
  });

  const maxSize = 15000000000; //1.5 gigabytes

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    accept: 'video/*',
    maxSize,
    multiple: true,
  });

  const isFileTooLarge =
    rejectedFiles &&
    rejectedFiles.length > 0 &&
    rejectedFiles[0].size > maxSize;

  return (
    <div>
      <div>
        <form style={{ padding: '25px' }}>
          {/* {console.log(props.newFilesList)} */}
          <div>
            <DropArea
              {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
            >
              <input
                style={{ padding: '25px' }}
                {...getInputProps()}
                type='file'
              />
              <h1 style={{ display: 'flex', justifyContent: 'space-between' }}>
                Drop or click to upload a video.
                <span>+</span>
              </h1>
              {isDragActive && !isDragReject && 'Drop to upload!'}
              {isDragReject && 'File type not accepted, sorry!'}

              {isFileTooLarge && (
                <div className='text-danger mt-2'>File is too large.</div>
              )}
            </DropArea>
          </div>
        </form>
        {Object.keys(props.newUploadProgress).length > 0 && (
          <div style={{ backgroundColor: 'red', padding: '25px' }}>
            <div
              style={{
                padding: '15px',
                borderRadius: '30px',
                border: '3px solid white',
              }}
            >
              <h3
                style={{
                  textDecoration: 'underline',
                }}
              >
                Upload Status
              </h3>

              <div>
                {/* {Object.keys(props.newUploadProgress).map((file, index) => ( */}
                {Object.keys(props.newUploadProgress).map((file, index) => (
                  <div key={index}>
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px',
                        margin: '0px',
                        fontWeight: 'bold',
                        alignItems: 'center',
                      }}
                    >
                      {props.newUploadProgress[file] !== 100 ? (
                        <span
                          style={{
                            padding: '15px',
                          }}
                        >
                          {file}
                        </span>
                      ) : (
                        <a
                          href={`/library/${file}`}
                          style={{
                            textDecoration: 'none',
                            color: 'black',
                            padding: '15px',
                          }}
                        >
                          {file}
                        </a>
                      )}
                      {typeof props.newUploadProgress[file] === 'string' ? (
                        <>
                          <span
                            style={{
                              maxWidth: '50%',
                              alignContent: 'flex-end',
                              textDecoration: 'none',
                              backgroundColor: 'white',
                              padding: '15px',
                              borderRadius: '30px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {props.newUploadProgress[file]}
                          </span>
                        </>
                      ) : (
                        <>
                          {props.newUploadProgress[file] !== 100 ? (
                            <span
                              style={{
                                maxWidth: '50%',
                                alignContent: 'flex-end',
                                textDecoration: 'none',
                                backgroundColor: 'white',
                                padding: '15px',
                                borderRadius: '30px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {props.newUploadProgress[file]}%
                              {/* <progress
                                value={props.newUploadProgress[file]}
                                max={'100'}
                                style={{
                                  width: '90%',
                                  marginLeft: '20px',
                                }}
                              /> */}
                              <progress
                                value={props.newUploadProgress[file]}
                                max={'100'}
                                style={{
                                  // width: '90%',
                                  marginLeft: '20px',
                                  marginRight: '5px',
                                }}
                              />
                            </span>
                          ) : (
                            <span>
                              <a
                                href={`/library/${file}`}
                                style={{
                                  textDecoration: 'none',
                                  backgroundColor: 'white',
                                  padding: '15px',
                                  borderRadius: '30px',
                                  maxWidth: '50%',
                                }}
                              >
                                <span
                                  style={{
                                    maxWidth: '50%',
                                    alignContent: 'flex-end',
                                    color: 'black',
                                  }}
                                >
                                  Open {file}
                                </span>
                              </a>
                            </span>
                          )}
                        </>
                      )}
                    </p>
                    {Object.keys(props.newUploadProgress).length - 1 !==
                      index && <hr />}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DropElement;
