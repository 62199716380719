import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const TextAreaStyle = styled.textarea`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
`;

export default function EditView(props) {
  let [Description, setDescription] = useState(props.eventData.description);
  let [Title, setTitle] = useState(props.eventData.title);

  const handleChange = (event) => {
    if (event.target.name === 'title') {
      event.persist();
      setTitle(event.target.value);
      console.log(Title);
    } else if (event.target.name === 'description') {
      setDescription(event.target.value);
    }
  };

  // useEffect(() => {
  //   console.log('Search message inside useEffect: ', Title);
  // }, [Title]);

  const saveChangesEditView = (id, Title, Description) => {
    console.log(Title);
    props.saveChanges(id, Title, Description);
    props.switchOutOfEditMode();
  };

  return (
    <div
      style={{
        border: 'grey solid 2px',
        borderRadius: '30px',
        marginBottom: '90px',
        margin: '30px',
      }}
    >
      <div
        style={{
          margin: '30px',
        }}
      >
        <h1>Edit Event ({props.eventData.time})</h1>
        <h5 style={{ marginBottom: '10px' }}>Title:</h5>
        <input
          value={Title}
          name='title'
          onChange={handleChange}
          placeholder='Enter a text'
        />
        <h5 style={{ marginTop: '5px' }}>Description:</h5>
        <TextAreaStyle
          value={Description}
          name='description'
          onChange={handleChange}
          placeholder='Enter text'
          style={{ boxSizing: 'border-box' }}
        />
        <div className='row'>
          <div className='col-6'>
            <Button
              className='btn btn-light'
              style={{ backgroundColor: '#9d978e' }}
              onClick={props.switchOutOfEditMode}
            >
              Cancel Changes
            </Button>
          </div>
          <div className='col-6'>
            <Button
              style={{ float: 'right' }}
              onClick={() =>
                saveChangesEditView(props.index, Title, Description)
              }
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
