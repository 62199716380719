import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import LoadingStyles from './Loading.module.css';

class Loading extends Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div
          style={{
            paddingBottom: '20px',
          }}
        >
          <ReactLoading
            type={'bars'}
            color={'red'}
            height={20}
            className={LoadingStyles.loading}
          />

          <h3
            style={{
              paddingTop: '40px',
            }}
          >
            Loading...
          </h3>
        </div>
      </div>
    );
  }
}

export default Loading;
