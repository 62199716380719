import React, { useState, useRef } from 'react';
// import './Accordion.css';
// import './NavDropdown.css';
// import './Navbar.css';
import AccordionStyle from './Accordion.module.css';
import NavbarStyle from './Navbar.module.css';
import { NavLink, useHistory, Link } from 'react-router-dom';
import { useAuth } from './../../Contexts/AuthContext';
import { Button, Modal } from 'react-bootstrap';

import KVXLogo from './../../KV Powered by X.png';

export default function Navbar() {
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState(null);
  const history = useHistory();
  const [show, setShow] = useState(false);
  // const [color, setColor] = useState(false);

  // Navbar Functions
  let nav_content = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [height, setHeight] = useState(0);

  function toggleMobileNavActive() {
    setIsActive(!isActive);
    setHeight(isActive ? 0 : nav_content.current.scrollHeight);
  }

  // Logout Function
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleLogout() {
    setError('');
    try {
      console.log('logout');

      await logout();
      history.push('/login');
    } catch (error) {
      setError('Failed to logout. Error: ' + error);
    }
  }

  return (
    <div>
      <nav style={{ backgroundColor: 'red' }}>
        {/* <nav style={{ backgroundColor: color ? color : 'red' }}> */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '175px',
            justifyContent: 'space-between',
            padding: '0px 1% ',
          }}
        >
          <div>
            <Link
              to='/'
              style={{
                textDecoration: 'none',
              }}
            >
              <img alt='' src={KVXLogo} height='120px' />
              <p
                style={{
                  margin: '0',
                  padding: '0',
                  color: 'black',
                }}
              >
                Hold your film sessions like a pro
              </p>
            </Link>
          </div>
          <div>
            <ul className={NavbarStyle['custom-nav-menu-desktop']}>
              {currentUser ? (
                <>
                  {LoggedInItems.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={NavbarStyle['custom-nav-item']}
                      >
                        <NavLink
                          exact
                          activeClassName={NavbarStyle['nav-active']}
                          className={NavbarStyle['custom-nav-links']}
                          to={item.path}
                        >
                          {item.title}
                        </NavLink>
                      </li>
                    );
                  })}
                  <li className={NavbarStyle['custom-nav-item']}>
                    <div
                      className={NavbarStyle['custom-nav-button']}
                      style={{ cursor: 'pointer' }}
                      onClick={handleShow}
                    >
                      Logout
                    </div>
                  </li>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Are you sure you want to logout?
                      </Modal.Title>
                    </Modal.Header>
                    {error && <div className='alert alert-danger'>{error}</div>}
                    <Modal.Footer>
                      <Button variant='secondary' onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button variant='danger' onClick={handleLogout}>
                        Log Out
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              ) : (
                <>
                  {LoggedOutItems.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={NavbarStyle['custom-nav-item']}
                      >
                        <NavLink
                          exact
                          activeClassName={NavbarStyle['nav-active']}
                          className={NavbarStyle['custom-nav-links']}
                          to={item.path}
                          // onClick={() => setClick(false)}
                        >
                          {item.title}
                        </NavLink>
                      </li>
                    );
                  })}
                </>
              )}
            </ul>
          </div>

          <div className={NavbarStyle['nav-desktop-menu']}>
            <ul>
              <li className={NavbarStyle['custom-nav-item']}>
                <div
                  className={NavbarStyle['menu-icon']}
                  onClick={toggleMobileNavActive}
                >
                  <i
                    className={
                      isActive
                        ? 'fas fa-times ' + NavbarStyle['fa-times']
                        : 'fas fa-bars ' + NavbarStyle['fa-bars']
                    }
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={NavbarStyle['accordion-section']}>
          <div
            ref={nav_content}
            style={{ maxHeight: `${height}px` }}
            className={NavbarStyle['accordion-content']}
          >
            <div className={AccordionStyle['accordion-content-text']}>
              <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>
                {currentUser ? (
                  <>
                    {LoggedInItems.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={NavbarStyle['custom-nav-item']}
                        >
                          <NavLink
                            exact
                            activeClassName={NavbarStyle['nav-active']}
                            className={NavbarStyle['nav-links-mobile']}
                            to={item.path}
                          >
                            {item.title}
                          </NavLink>
                        </li>
                      );
                    })}
                    <li className={NavbarStyle['custom-nav-item']}>
                      <button
                        className={
                          NavbarStyle['dropdown-nav-button'] +
                          ' ' +
                          AccordionStyle['custom-accordion-button']
                          // ''
                        }
                        onClick={handleShow}
                      >
                        <span className={AccordionStyle['accordion-title']}>
                          Logout
                        </span>
                      </button>
                    </li>
                  </>
                ) : (
                  <>
                    {LoggedOutItems.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={NavbarStyle['custom-nav-item']}
                        >
                          <NavLink
                            exact
                            activeClassName={NavbarStyle['nav-active']}
                            className={NavbarStyle['nav-links-mobile']}
                            to={item.path}
                            // onClick={() => setClick(false)}
                          >
                            {item.title}
                          </NavLink>
                        </li>
                      );
                    })}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export const LoggedInItems = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Library',
    path: '/library',
  },
  {
    title: 'Account',
    path: '/account',
  },
];

export const LoggedOutItems = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Login',
    path: '/login',
  },
  {
    title: 'Sign Up',
    path: '/sign-up',
  },
];
