import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './Contexts/AuthContext';
import Footer from './Navigation/Footer';
import { StorageProvider } from './Contexts/StorageContext';
import NavNew from './Navigation/Navbar/Navbar2';

export default function PrivateRoute({
  component: Component,
  publicComponent: PublicRoute,
  ...rest
}) {
  const { currentUser } = useAuth();
  return (
    <div>
      <Route
        {...rest}
        render={(props) => {
          return currentUser ? (
            <StorageProvider>
              <NavNew />
              <Component {...props} />
              <Footer />
            </StorageProvider>
          ) : PublicRoute ? (
            <div>
              {/* <Nav /> */}
              <NavNew />
              <PublicRoute {...props} />
              <Footer />
            </div>
          ) : (
            <Redirect to='/login' />
          );
        }}
      />
    </div>
  );
}
