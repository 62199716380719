import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import MarkedEvents from '../FileViewer/MarkedEventsList';
import ReactPlayer from 'react-player';
import FirebaseConfiguration from '../FirebaseConfiguration';
import fileViewerStyles from './Player.module.css';
import { v4 as uuidv4 } from 'uuid';

export default function FileViewer(props) {
  const [settingsModal, setSettingsModal] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [eventsListFB, setEventsListFB] = useState([
    {
      id: 1,
      title: 'Steal to Dunk',
      time_seconds: 16,
      time: '0:16',
      description:
        'Ian anticipates a pass, gets a steal leading to an assist and dunk',
      date_added: new Date(),
    },
    {
      id: 2,
      title: 'Gambling  for a steal leads to a layup',
      time_seconds: 36,
      time: '0:36',
      description:
        'Sam gambles for a steal forcing Nick to play aggressive help D, leading to an easy layup.',
      date_added: new Date(),
    },
    {
      id: 3,
      title: 'Missed easy layup opportunity',
      time_seconds: 102,
      time: '1:42',
      description:
        'Noah avoids the left handed layup leading to an easy layup on the other end',
      date_added: new Date(),
    },
    {
      id: 4,
      title: 'Good D',
      time_seconds: 102,
      time: '1:42',
      description: 'Good Defense even though it lead to a basket',
      date_added: new Date(),
    },
    {
      id: 5,
      title: 'Good Pick and Roll Defense',
      time_seconds: 43,
      time: '0:43',
      description: '',
      date_added: new Date(),
    },
    {
      id: 6,
      title: 'Gambling  for a steal leads to a layup',
      time_seconds: 53,
      time: '0:53',
      description: '',
      date_added: new Date(),
    },
    {
      id: 7,
      title:
        'Noah avoids the left handed layup leading to an easy layup on the other end',
      time_seconds: 109,
      time: '1:49',
      description: '',
      date_added: new Date(),
    },
    {
      id: 8,
      title: 'Poor transition defense',
      time_seconds: 118,
      time: '1:58',
      description: '',
      date_added: new Date(),
    },
    {
      id: 9,
      title: 'Good Team defense lead to steal and layup on other end',
      time_seconds: 199,
      time: '3:19',
      description: '',
      date_added: new Date(),
    },
    {
      id: 10,
      title: 'Good Ball movement and steal',
      time_seconds: 278,
      time: '4:38',
      description: '',
      date_added: new Date(),
    },
    {
      id: 11,
      title: 'Missed box-out that leads to offensive rebound',
      time_seconds: 288,
      time: '4:48',
      description: '',
      date_added: new Date(),
    },
    {
      id: 12,
      title: 'Good ball movement',
      time_seconds: 537,
      time: '5:37',
      description: '',
      date_added: new Date(),
    },
    {
      id: 12,
      title: 'Bad defense but good help',
      time_seconds: 349,
      time: '5:49',
      description: '',
      date_added: new Date(),
    },
  ]);
  const [VideoURL, setVideoURL] = useState();
  const [sidebarWidth, setSidebarWidth] = useState('');
  let sideBar = React.useRef(null);

  let videoPlayer = React.createRef();

  async function MarkTime() {
    let time = videoPlayer.current.getCurrentTime();

    var docData3 = {
      id: uuidv4(),
      title: 'No Title',
      time_seconds: time,
      time: secondsToTime(time),
      description: '',
      date_added: new Date(),
    };

    setEventsListFB([...eventsListFB, docData3]);
  }

  async function saveChanges(index, eventTitle, eventDescription) {
    let newData = eventsListFB[index];
    newData.title = eventTitle;
    newData.description = eventDescription;
    function remove(array, element) {
      const index = array.indexOf(element);
      if (index !== -1) {
        array.splice(index, 1);
      }
    }
    let newArray = eventsListFB;
    remove(newArray, eventsListFB[index]);
    setEventsListFB([...newArray, newData]);
    console.log('save changes on Firebase');
  }

  function secondsToTime(e) {
    var h = Math.floor(e / 3600)
        .toString()
        .padStart(2, '0'),
      m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, '0'),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, '0');

    if (h === '00') {
      h = '';
    } else {
      h = `${h}:`;
    }
    if (m === '00') {
      m = '0';
    }

    return h + m + ':' + s;
  }

  function deleteEvent(index) {
    const updateArray = eventsListFB.filter((_, i) => i !== index);
    setEventsListFB(updateArray);
  }

  function StartAtSelectedTime(time) {
    videoPlayer.current.seekTo(time);
  }

  function CenteredSettingsModal(props) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Coming Soon</h4>
          <p>Customization Options Coming Soon</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function CenteredFileModal(props) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            File Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12'>
              <form>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <input
                    type='text'
                    placeholder='test.mp4'
                    style={{ width: '55%' }}
                    // ref={customName}
                  />
                  <Button>Reset to Original Name</Button>
                  <Button type='submit'>Rename File</Button>
                </div>
              </form>
            </div>
            <hr style={{ marginTop: '20px' }} />
            <div className='col-12' style={{ width: '100%' }}>
              <Button
                variant='danger'
                // onClick={DeleteFile}
                style={{ width: '100%' }}
              >
                Delete File and Saved Events
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  useEffect(() => {
    function loadVideo() {
      const storageRef = FirebaseConfiguration.storage().ref();
      storageRef
        .child('PublicHome/Test.mp4')
        .getDownloadURL()
        .then(function (url) {
          console.log(url);
          setVideoURL(url);
        });
    }

    loadVideo();
  }, []);

  // const startSidebarWidth = sideBar.current.offsetWidth;

  function closeSidebar() {
    setHideSidebar(!hideSidebar);
  }

  function closeFileModal() {
    setFileModal(false);
  }

  const [hideSidebar, setHideSidebar] = useState(true);

  let displayName = 'Test.mp4';

  return (
    <div className='container-fluid' style={{ minHeight: '95vh' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '10px',
        }}
      >
        <h3>{displayName}</h3>
        <Button
          className='btn btn-secondary'
          onClick={() => setFileModal(true)}
        >
          <h1>
            <i
              className='fa fa-gear'
              style={{ fontSize: '24px', textAlign: 'center' }}
            ></i>
          </h1>
        </Button>
        <CenteredFileModal show={fileModal} onHide={closeFileModal} />
      </div>
      <div className='row'>
        <div className='col'>
          <div
            className={fileViewerStyles['wrapper']}
            style={{ marginBottom: '30px' }}
          >
            <ReactPlayer
              ref={videoPlayer}
              className={fileViewerStyles['player']}
              url={VideoURL}
              width='100%'
              height='100%'
              controls
            />
          </div>
          <form>
            <div className={'row ' + fileViewerStyles['space-top']}>
              <div className='col-10'>
                <Button className='col-12' onClick={MarkTime}>
                  <h1>Mark Event</h1>
                </Button>
              </div>
              <div className='col-2'>
                <Button
                  className='col-12 btn btn-secondary'
                  onClick={() => setSettingsModal(true)}
                >
                  <h1>
                    <i
                      className='fa fa-gear'
                      style={{ fontSize: '24px', textAlign: 'center' }}
                    ></i>
                  </h1>
                </Button>
                <CenteredSettingsModal
                  show={settingsModal}
                  onHide={() => setSettingsModal(false)}
                />
              </div>
            </div>
          </form>
        </div>

        <div
          className='col-auto d-none d-lg-xl d-lg-block '
          style={{ padding: '0px' }}
        >
          <button
            onClick={closeSidebar}
            className='active'
            style={{
              height: '100%',
              width: '50px',
              background: '#F8F8F8',
              border: '1px solid #484848',
              fontSize: '40px',
            }}
          >
            &rsaquo;
          </button>
        </div>
        <div
          className={
            hideSidebar
              ? 'col-3 d-none d-lg-xl d-lg-block ' +
                fileViewerStyles['sidebar-visible']
              : fileViewerStyles['sidebar-hidden']
          }
        >
          <div
            ref={sideBar}
            style={{
              maxWidth: `${sidebarWidth}px`,
              overflow: 'hidden',
              transition: 'max-width .25s',
              // whiteSpace: 'nowrap',
            }}
          >
            <div
              ref={sideBar}
              style={{
                // maxWidth: `${sidebarWidth}px`,
                right: `${sidebarWidth}px`,
                // overflow: 'hidden',
                transition: 'max-width 10s',
                // border: '1px solid black',
              }}
            >
              <div style={{ padding: '10px 0px' }}>
                <h1
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Marked Events
                </h1>
                <div className={fileViewerStyles['side_list']}>
                  <MarkedEvents
                    hideSidebar={hideSidebar}
                    events={eventsListFB}
                    deleteEvent={deleteEvent}
                    StartAtSelectedTime={StartAtSelectedTime}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: '30px 0px',
        }}
      >
        <hr
          style={{
            borderBottom: 'dashed 3px',
            color: 'red',
          }}
        />
      </div>

      <div
        className='row'
        style={{
          border: '5px solid black',
          borderRadius: '20px',
          margin: '50px 0',
        }}
      >
        <div
          className='col-md'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              backgroundColor: 'white',
              borderRadius: '20px',
              padding: '2px 10px',
              margin: '1.5vh 0',
            }}
          >
            <h1
              style={{
                lineHeight: '1.8',
              }}
            >
              Mark the most important events to review during your team's film
              session.
            </h1>
            <h3>
              Once you Tag an event, jump to the event from the sidebar (desktop
              only). You can also use the list of events to jump to tagged
              events.
            </h3>
            <hr />
            <h3>
              Edit an Events Title and Comments in the bottom Events List.
            </h3>
          </div>
        </div>
        <div className='col-md-3' style={{ minWidth: '300px' }}>
          <h1 style={{ textAlign: 'center' }}>Marked Events</h1>
          <div className=''>
            <div
              style={{
                maxHeight: '350px',
              }}
              className={fileViewerStyles['side_list']}
            >
              <MarkedEvents
                hideSidebar={hideSidebar}
                events={eventsListFB}
                deleteEvent={deleteEvent}
                StartAtSelectedTime={StartAtSelectedTime}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: '50px',
          marginBottom: '50px',
        }}
      >
        <hr
          style={{
            borderBottom: 'dashed 3px',
            color: 'red',
          }}
        />
      </div>

      <div className='row'>
        <div className='col-12' key={eventsListFB.id}>
          {eventsListFB.length === 0 && (
            <div style={{ margin: '30px 0px 50px', textAlign: 'center' }}>
              <h1>No Events Marked Yet</h1>
            </div>
          )}
          <div>
            <MarkedEvents
              events={eventsListFB}
              large={true}
              deleteEvent={deleteEvent}
              saveChanges={saveChanges} //!TODO: Implement Firebase Save Changes
              StartAtSelectedTime={StartAtSelectedTime}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
