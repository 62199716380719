import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import KVLogo from './../KV Logo.png';

const Navigation = styled.div`
  opacity: 96%;
  background-color: red;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function Footer() {
  return (
    <div>
      <Navigation>
        <Link to='/'>
          <img src={KVLogo} alt='logo' height={'100px'} />
        </Link>
        <span>
          &copy; {new Date().getFullYear()} -{' '}
          <Link to='/' style={{ color: 'black' }}>
            KoachView
          </Link>{' '}
          powered by{' '}
          <a href='https://xhighlight.com' style={{ color: 'black' }}>
            XHighlight
          </a>
          <p style={{ textAlign: 'center' }}>
            By:{' '}
            <a href='https://alvie.org' style={{ color: 'black' }}>
              Alvie Stoddard
            </a>
          </p>
        </span>
      </Navigation>
    </div>
  );
}
