import React, { useRef, useEffect } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './../Navigation/Navbar/Navbar2';
import Footer from './../Navigation/Footer';
import { useAuth } from '../Contexts/AuthContext';
import styles from './Login.module.css';

export default function Signup() {
  const { currentUser } = useAuth();

  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = React.useState('');
  const [firebaseError, setFirebaseError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  async function handleSubmit(event) {
    event.preventDefault();
    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      return setError('Passwords to not match');
    }
    // try {
    setError('');
    setFirebaseError('');
    setLoading(true);
    console.log(emailRef.current.value);
    console.log(passwordRef.current.value);
    await signup(emailRef.current.value, passwordRef.current.value).catch(
      (err) => {
        console.log(err);
        switch (err.code) {
          default:
            setFirebaseError(err.message);
          // case 'auth/email-already-in-use':
          // case 'auth/invalid-email':
          //   setFirebaseError(err.message);
          //   console.log(firebaseError);
          //   break;
          // case 'auth/weak-password':
          //   setFirebaseError(err.message);
          //   console.log(firebaseError);

          //   break;
        }
      }
    );
    // history.push('/');
    // } catch {
    //   setError('Failed to create an account');
    // }
    setLoading(false);
  }

  useEffect(() => {
    if (currentUser) {
      history.push('/');
    }
  });

  return (
    <div>
      <NavBar />
      <div
        className='container-fluid d-flex align-items-center justify-content-center'
        style={{ minHeight: '100vh', background: '#e9e9e9' }}
      >
        <div className='w-100' style={{ maxWidth: '500px' }}>
          <div>
            <Card
              style={{
                borderRadius: '30px',
                boxShadow: '0 50px 70px -20px rgba(0, 0, 0, 0.8)',
                padding: '40px',
              }}
            >
              <Card.Body>
                <h2 className='text-center mb-4'>Sign Up For KoachView</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                {firebaseError && (
                  <Alert variant='danger'>{firebaseError}</Alert>
                )}
                <Form onSubmit={handleSubmit}>
                  <Form.Group id='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      className={styles['auth-input']}
                      type='email'
                      ref={emailRef}
                      placeholder='email@email.com'
                      required
                    />
                  </Form.Group>
                  <Form.Group id='password' className='mt-4'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      className={styles['auth-input']}
                      type='password'
                      ref={passwordRef}
                      placeholder='password'
                      required
                    />
                  </Form.Group>
                  <Form.Group id='passwordConfirm' className='mt-4'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      className={styles['auth-input']}
                      type='password'
                      placeholder='password'
                      ref={confirmPasswordRef}
                      required
                    />
                  </Form.Group>
                  <Button
                    className={
                      styles['auth-button'] + ' mt-4 w-100 shadow-none'
                    }
                    disabled={loading}
                    variant='primary'
                    type='submit'
                  >
                    Sign Up
                  </Button>
                </Form>
              </Card.Body>
              <div style={{ textAlign: 'right' }} className='w-100 mt-2'>
                Already have an account?{' '}
                <Link to='login' className={styles['switch-button']}>
                  Log In
                </Link>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
